import {http} from './http-common'
const API_URL =  '/auth';

//let BILLING_URL = "";

class AuthenticationService {
    // async getBillingUrl() {
    //     if (BILLING_URL === null || BILLING_URL === '') {
    //         BILLING_URL = await getBillingInfo();
    //     }
    //     return BILLING_URL;
    // }

    loginRequest(payload) {
        return http.post(API_URL+"/login", payload)
    }

    stayLoginRequest(token){
        return http.get(API_URL+"/stay-login?token="+token)
    }

    resetRequest(payload){
        // return http.post(API_URL+"/reset", payload)
        return http.post(API_URL + "/reset-password", payload);
    }

    updatePasswordRequest(password, resetPassword, passwordChangeOption,token) {
        return http.post(API_URL +
            "/update-password?password=" + password +
            "&resetPassword=" + resetPassword +
            "&passwordChangeOption=" + passwordChangeOption +
            "&token=" + token);
    }
    getBillingVersion(){
        return http.get(API_URL+"/version");
    }

    loginByOTP2StepVerification(otp, username, password, otpType = "email"){
        let loginFormPayload = {
            username: username,
            password:password,
            otp: otp,
            otpType: otpType
        }
        return http.post(API_URL + "/loginByOTP2StepVerification", loginFormPayload);
    }


    sendSoftwarePush(tokenID, username){
        let sendPushPayload = {
            tokenID: tokenID,
            username: username
        };

        return http.post(API_URL + "/send-push", sendPushPayload);
    }
    loginBySoftwarePush(tnxID, username,password){
        let reveSecurePayload = {
            username: username,
            password:password,
            tnxID: tnxID
        }
        return http.post(API_URL +
            "/validate-push",reveSecurePayload
        );
    }


    loginBySoftwareOTP(otp,username, password,authType = "S"){
        let reveSecurePayload = {
            username: username,
            password:password,
            otp: otp,
            authType: authType
        }
        return http.post(API_URL + "/validate-otp", reveSecurePayload);
    }

    refreshAccessToken(oldToken,refreshToken){
        return http.get(API_URL+"/refresh-access-token?"+"oldToken="+oldToken+"&refreshToken="+refreshToken).then(res=>{
            console.log("maahi",res.data);
            return res.data;
        }).catch(error=>{
            console.log(error);
        });
    }

}

export default new AuthenticationService()
