const dashBoardModule = {
    namespaced: true,
    state: {
        totalSMSLast30days: localStorage.getItem("dashBoardData"),
        timestamp: localStorage.getItem("dashBoardDataTimestamp"), // To store the time when data was set
    },
    mutations: {
        setTotalSMSLast29days(state, dashBoardData) {
            state.totalSMSLast30days = dashBoardData.smsCountLastMonth;
            localStorage.setItem("dashBoardData", dashBoardData.smsCountLastMonth); // Ensure this is being set in localStorage
            console.log(new Date().getTime(), dashBoardData.timeDifference, parseInt(dashBoardData.timeDifference), new Date().getTime() + parseInt(dashBoardData.timeDifference))
            localStorage.setItem("dashBoardDataTimestamp", new Date().getTime() + parseInt(dashBoardData.timeDifference)); // Store timestamp as well
        },
        clearTotalSMSLast29days(state) {
            state.totalSMSLast30days = null;
            state.timestamp = null;
            localStorage.removeItem("dashBoardData");
            localStorage.removeItem("dashBoardDataTimestamp");
        },
    },
    actions: {
        setTotalSMSLast29days({ commit }, dashBoardData) {
            commit('setTotalSMSLast29days', dashBoardData);
        },
        checkDataExpiry({ commit, state }) {
            const currentTime = new Date().getTime();
            const storedTimestamp = parseInt(state.timestamp);

            // Check if 24 hours have passed (24 hours = 86400000 milliseconds)
            if (currentTime>storedTimestamp) {
                commit("clearTotalSMSLast29days");
            }
        },
    },
    getters: {
        getTotalSMSLast29days: (state) => {
            return  state.totalSMSLast30days ;
        },
    },
};

export default dashBoardModule;





