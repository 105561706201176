import axios from 'axios'

import router from '@/router/router';
import store from '@/store/vuex';
import ConfigService from "@/service/ConfigService";
import AuthenticationService from "@/service/AuthenticationService";

//toast notifier import
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
//end toast notifier import


const $toast = useToast();

export const http = axios.create({
    baseURL: await ConfigService.getBaseAPIUrl(),
    headers: {
        'Content-type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Strict-Transport-Security": " max-age=63072000 ; includeSubDomains; preload"
    }
});

let url = "";
http.interceptors.request.use(async config => {

    // Get the token from where you have stored it (e.g., localStorage or Vuex)
    //const userDetails = store.getters["auth/userDetails"]; // Access the entire Vuex state object
    const userDetails = JSON.parse(localStorage.getItem("userDetails"))
    let token = '' // Access a specific property from the state (e.g., token)
    if(userDetails && userDetails.idToken){
        token = userDetails.idToken;
        // Add the token to the request headers
        config.headers['Authorization']  = `Bearer ${token}`;
    }
    url = config.url;
    //
    // // Add the current timestamp to the headers (in UTC format)
    // const requestTime = new Date().toISOString();
    // config.headers['X-Request-Time'] = requestTime;  // Add request time header
    return config;
}),
    http.interceptors.response.use(null, async error => {
        const originalRequest = error.config;
        // Check if the error is due to an expired access token
        if (error !== undefined && error.response !== undefined && error.response.status === 401
            && error.response.data.message == "Expired JWT token" && !originalRequest._retry) {
            originalRequest._retry = true;

            // Perform token refresh
            const userDetails = JSON.parse(localStorage.getItem("userDetails"))
            let newAccessToken=null;
            if(userDetails && userDetails.idToken){
                newAccessToken = await AuthenticationService.refreshAccessToken(userDetails.idToken, userDetails.refreshToken);
            }
            if (newAccessToken) {
                // Update the access token and retry the original request
                userDetails.idToken = newAccessToken.left;
                userDetails.refreshToken = newAccessToken.right;
                store.dispatch("auth/setUserDetails",userDetails);
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken.left}`;
                return axios(originalRequest);
            }
        } else if(error !== undefined && error.response !== undefined && error.response.status == 429){
            console.log("Error")
            $toast.warning(error.response.data.message, {position: "top-right"});
        } else if (error !== undefined && error.response !== undefined && error.response.status == 401 && !url.includes("api/auth")) {
            store.dispatch('auth/clearUserDetails');
            store.dispatch('serverTimeStore/clearServerTime');
            router.push({name: 'login'});
        } else {
            // alertPopUp.showAlert(error.response.data.details, false);
            return Promise.reject(error)
        }
    })
