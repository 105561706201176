<script setup>
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {PieChart, BarChart, LineChart} from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';

import {ToolboxComponent} from 'echarts/components';
import {GridComponent} from 'echarts/components';
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {onBeforeMount} from "vue";
import ConfigService from "@/service/ConfigService";
import SSEventService from "@/service/SSEventService";
import BrowserTabService from "@/service/BrowserTabService";


use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  BarChart,
  LineChart,
  ToolboxComponent,
  GridComponent

]);

const store = useStore();
const router = useRouter()

onBeforeMount(() => {
  console.log("DEBUG:: App.vue-onBeforeMount");
  //this method is so much important.
  BrowserTabService.onTabOpened();
  init();

  setTimeout(async () => {
    document.title = await ConfigService.getPortalName();
  },10);

  if (!store.getters["auth/isLoggedIn"]) {
    ///allow user to access
    console.log("Path is: ", window.location.hash);
    let path = window.location.hash.split("/")[1];
    if (!path.includes('update-password') && !path.includes('reset-password') && !path.includes('login')) {
      router.push('/login');
    }
  }
  console.log("DEBUG: App.vue-onBeforeMount-end");
})

function init() {
  // Handle when a tab is unloaded
  window.addEventListener('beforeunload', (event) => {
    console.log("beforeunload", event);
    BrowserTabService.onTabClosed();
    SSEventService.closeEventSource();
  });
}
</script>

<template>
  <div>
    <div id="root">
      <router-view/>
    </div>
  </div>
</template>

<style lang="scss">

#root {
  font-family: Inter, sans-serif !important;
  line-height: 1.5rem !important;
  font-size: 0.81rem !important;
  font-weight: 400 !important;
  background-color: var(--system-body-background-color) !important;
  color: var(--system-text-color) !important;
}

.input[type="text"] {
  color: var(--system-text-color);
  width: 100%;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:hover {
  border-color: #646cff;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

select {
  border: 1px solid var(--system-input-border-color);
  color: var(--system-text-color);
  background-color: var(--system-content-background-color);
}
</style>

