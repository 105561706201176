// Local Storage Key Constants
const TABS_KEY_PREFIX = 'tab_';
const USER_DETAILS_KEY = 'userDetails';


const ACTIVE_TIME = Date.now();


class BrowserTabService {
    getSignature(){
        if(!sessionStorage.getItem("TAB_SIGNATURE")){
            const TAB_SIGNATURE =  ACTIVE_TIME.toString() + Math.random().toString(36).substring(2);
            sessionStorage.setItem("TAB_SIGNATURE", TAB_SIGNATURE);
        }
        return sessionStorage.getItem("TAB_SIGNATURE");
    }

    getNumberOfOpenTabs(){
        let numberOfTabs = 0;
        Object.keys(localStorage).forEach(key => {
            if (key.includes('tab_')) {
                numberOfTabs++;
            }
        });
        console.log('Number of tabs open: ', numberOfTabs);
        return numberOfTabs;
    }

    // When a tab is opened
    onTabOpened() {
        console.log("DEBUG:: tab opening...");
        const numberOfTabs = sessionStorage.getItem('numberOfTabs');
        const userDetails = sessionStorage.getItem(USER_DETAILS_KEY);
        console.log('DEBUG:: Number of tabs open: ', numberOfTabs, 'userDetails: ', userDetails, 'typeof userDetails: ',typeof userDetails);
        if (numberOfTabs && numberOfTabs === '0' && userDetails) {
            console.log('DEBUG:: Setting localStorage by sessionStorage', userDetails)
            sessionStorage.removeItem('numberOfTabs');
            localStorage.setItem(USER_DETAILS_KEY, userDetails);

            console.log('DEBUG:: User details removed from session storage');
            sessionStorage.removeItem(USER_DETAILS_KEY);
        }
        //write tabSignature to local storage
        localStorage.setItem(`${TABS_KEY_PREFIX}${this.getSignature()}`, ACTIVE_TIME.toString());
        console.log('DEBUG:: Tab opened at ', ACTIVE_TIME.toString());
    }

    // When a tab is closed
    onTabClosed() {
        //remove own tab signature.
        localStorage.removeItem(`${TABS_KEY_PREFIX}${this.getSignature()}`);
        const userDetails = localStorage.getItem(USER_DETAILS_KEY);

        const numberOfTabs = this.getNumberOfOpenTabs();
        sessionStorage.setItem("numberOfTabs", numberOfTabs.toString());
        if(numberOfTabs === 0 && userDetails){
            sessionStorage.setItem(USER_DETAILS_KEY, userDetails);
            localStorage.removeItem(USER_DETAILS_KEY);
        }
    }
}

export default new BrowserTabService();
