import ConfigService from "@/service/ConfigService";
import {http} from "@/service/http-common";
import BrowserTabService from "@/service/BrowserTabService";

const API_URL = '/sse-connection';

//const eventSourcePropertyName = "numberOfSSEConnections";

class SSEventService {
    constructor() {
        this.eventSource = null;
        this.backendURL = null;
        this.retryCount = 3;
        this.limitOfSSEConnections = 5;
        this.serverTime = null;
    }

    async delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async getSSEConnection(username) {
        //init
        if (!this.backendURL) {
            this.backendURL = await ConfigService.getBaseAPIUrl();
            if(this.backendURL.startsWith("https://")){
                //modify the limit of sse connections.
                this.limitOfSSEConnections = 98;
            }
        }
        //end init

        this.retryCount = 3;
        while (this.retryCount > 0 && (!this.eventSource || this.eventSource.readyState === EventSource.CLOSED)) {
            try {
                console.log("Retries left: ", this.retryCount);
                this.retryCount--;

                const numberOfOpenTabs = BrowserTabService.getNumberOfOpenTabs();
                if (numberOfOpenTabs >= this.limitOfSSEConnections) {
                    window.alert("You have too many tabs open! Please close this one. Otherwise you may have lost your connection.");
                    window.close();
                    return null; // Exit early without retrying further
                }

                const tabSignature = BrowserTabService.getSignature();
                if (username && tabSignature) {
                    const url = `${this.backendURL}${API_URL}/get?username=${username}&clientSignature=${tabSignature}`;
                    this.eventSource = new EventSource(url);
                    this.initializeEventSource();
                    console.log("EventSource initialized successfully. Event Source: ", this.eventSource);
                }
            } catch (error) {
                console.error("Error in initializing SSE connection:", error);
                this.eventSource = null;
            }
        }
        return this.eventSource;
    }

    initializeEventSource() {
        if (!this.eventSource) {
            console.error("EventSource is not initialized.");
            return;
        }

        // this.eventSource.onmessage = (event) => {
        //     console.log("Event from backend:", event);
        // }
        // this.eventSource.addEventListener('sseConnectionCount', (event) => {
        //     console.log("Event source count from backend:", event.data);
        //     localStorage.setItem(eventSourcePropertyName, event.data || 0);
        // });

        this.eventSource.addEventListener(this.tabSignature + '-connection-limit', (event) => {
            console.log("Event source connection limit from backend:", event);
            this.closeEventSource();
            window.alert("You have too many tabs open! Please close this one. Server pushed this message.");
            window.close();
        });

        this.eventSource.addEventListener('serverTime', (event) => {
            console.log("Server Time: ", event.data);
            this.serverTime = event.data;
        });

        this.eventSource.onerror = (error) => {
            console.error("EventSource error:", error);
            if (error?.currentTarget?.readyState === EventSource.CLOSED) {
                console.log("INFO:: EventSource is already closed, cleaning up...");
                this.closeEventSource();
                window.alert("Connection to server lost. Please refresh the page to reconnect.");
                location.reload();
            }
        };
    }

    getServerTime(){
        return this.serverTime;
    }

    closeEventSource() {
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = null;
            console.log("Event Source Closed!");
        }
    }

    async getLeftOverConnectionLimit() {
        try {
            const response = await http.get(API_URL + "/get-sse-left-over-connection");
            return response;
        } catch (error) {
            console.error("Error fetching connection limit:", error);
            throw error;
        }
    }

    addEventListenerToEventSource(eventType, callback) {
        if (!this.eventSource) {
            console.error("Cannot add event listener. EventSource is not initialized.");
            return;
        }

        this.eventSource.addEventListener(eventType, callback);
    }
}

export default new SSEventService();
