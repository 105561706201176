//systemMenuList is already defined in menuItems.js

import SSEventService from "@/service/SSEventService";

const authModule = {
    namespaced: true,
    state: {
        alertStatus: true,
        userDetails: JSON.parse(localStorage.getItem("userDetails")),
        currentSubMenu: {}
    },
    mutations: {
        setUserDetails(state, userDetails) {
            state.userDetails = userDetails;
            localStorage.setItem("userDetails", JSON.stringify(userDetails));
        },

        clearUserDetails(state) {
            state.userDetails = null;
            SSEventService.closeEventSource();
            localStorage.removeItem('userDetails');
        },
        setCurrentSubMenu(state, currentSubMenu) {
            console.log('setCurrentSubMenu ', currentSubMenu);
            state.currentSubMenu = currentSubMenu;
        }
    },
    actions: {
        setUserDetails({commit}, userDetails) {
            commit('setUserDetails', userDetails);
        },
        clearUserDetails({commit}) {
            commit('clearUserDetails');
        },
        setCurrentSubMenu({commit}) {
            commit('setCurrentSubMenu');
        },
    },
    getters: {
        userDetails: (state) => {
            state.userDetails = JSON.parse(localStorage.getItem("userDetails"));
            return state.userDetails;
        },
        getUserToken:(state) => state.userDetails.idToken,
        isLoggedIn: (state) => {
            state.userDetails = JSON.parse(localStorage.getItem("userDetails"));
            return state.userDetails && state.userDetails.idToken !== null
        },
        ///Role Based Checks
        getRole: (state) => (state.userDetails != null && state.userDetails.role != null) ? state.userDetails.role : "",
        getRoleSpecialIdentifier: (state) => (state.userDetails != null && state.userDetails.roleSpecialIdentifier != null) ? state.userDetails.roleSpecialIdentifier : "",
        getUserRoleID: (state)=> (state.userDetails != null && state.userDetails.roleID != null) ? state.userDetails.roleID : -1,
        getRecordsPerPage: (state) => (state.userDetails != null && state.userDetails.recordPerPage != null) ? state.userDetails.recordPerPage : 15,
        isReseller: (state) => (state.userDetails != null && state.userDetails.role != null && state.userDetails.role == 'ROLE_RESELLER'),
        isAdmin: (state) => (state.userDetails != null && state.userDetails.role != null && state.userDetails.role == 'ROLE_ADMIN'),
        isAccountManager: (state) => (state.userDetails != null && state.userDetails.role != null && state.userDetails.role == 'ROLE_ACCOUNTMANAGER'),
        isOriginator: (state) => (state.userDetails != null && state.userDetails.role != null && state.userDetails.role == 'ROLE_ORIGINATOR'),
        isTerminator: (state) => (state.userDetails != null && state.userDetails.role != null && state.userDetails.role == 'ROLE_TERMINATOR'),
        isDippingEnable: (state) => (state.userDetails != null && state.userDetails.dippingEnable != null && state.userDetails.dippingEnable == true),
        isHLREnable: (state) => (state.userDetails != null && state.userDetails.hlrEnable != null && state.userDetails.hlrEnable == true),
        isContentWhitelistEnable: (state) => (state.userDetails != null && state.userDetails.contentWhitelistEnable != null && state.userDetails.contentWhitelistEnable == true),
        getUserID: (state) => state.userDetails.userID,
        getUserName: (state) => (state.userDetails != null && state.userDetails.username != null) ? state.userDetails.username : "",
        getAccountID: (state) => state.userDetails.accountID,

        isUserAuthorized: (state) => {
            return (arrayOfroles) => { // arg: [] of role; role can reseller, admin, originator, terminator
                //console.log(arrayOfroles);
                for (let role of arrayOfroles) {
                    role = "ROLE_" + role.toUpperCase();
                    if (state.userDetails != null && state.userDetails.role != null && role == state.userDetails.role) {
                        return true;
                    }
                }
                return false;
            }
        },

        hasUserMenuPermission: (state) => {
            return (parentMenuID, subMenuID) => { // arg: add/search menu id; submenuID
                console.log(parentMenuID, subMenuID);
                if (state.userDetails == null || state.userDetails.menuPermission == null) {
                    console.log("values are null")
                    return 0;
                }

                if (state.userDetails.menuPermission[parentMenuID] != null && state.userDetails.menuPermission[parentMenuID] > 0
                    && state.userDetails.menuPermission[subMenuID] != null && state.userDetails.menuPermission[subMenuID] > 0) {
                    return state.userDetails.menuPermission[subMenuID];
                }
                return 0;
            }
        }
    },
};

export default authModule;




