const columnPreferenceModule = {
    namespaced: true,
    state: {
        columnPreferenceObject: JSON.parse(localStorage.getItem("columnPreferenceObject")) || {}
    },
    mutations: {
        setColumnsMutation(state, { accountID, componentView, columns }) {
            const accountIDStr = String(accountID);
            if (!state.columnPreferenceObject) {
                state.columnPreferenceObject = {};
            }
            if (!state.columnPreferenceObject[accountIDStr]) {
                state.columnPreferenceObject[accountIDStr] = {};
            }
            state.columnPreferenceObject[accountIDStr][componentView] = columns;
            localStorage.setItem("columnPreferenceObject", JSON.stringify(state.columnPreferenceObject));
        }
    },
    actions: {
        setColumns({ commit }, { accountID, componentView, columns }) {
            commit('setColumnsMutation', { accountID, componentView, columns });
        },
    },
    getters: {
        getColumns: (state) => (accountID, componentView) => {
            const accountIDStr = String(accountID);
            if (!state.columnPreferenceObject) return null;
            if (!state.columnPreferenceObject[accountIDStr]) return null;
            if (!state.columnPreferenceObject[accountIDStr][componentView]) return null;
            return state.columnPreferenceObject[accountIDStr][componentView];
        },
    }
};

export default columnPreferenceModule;
